<template>
  <div class="introduce">
    <div class="title1">群成员（{{ memberList&&memberList.length }}）</div>
    <ul class="people">
      <li v-for="(item, index) in memberList" :key="index">
        <img src="~@/assets/images/avatar.png" />
        <span>{{ item.imMemberName }}</span>
      </li>
    </ul>
    <div class="introduce_con">
      <div class="title2">群介绍</div>
      <div class="txt">
        本群为<span v-if="curGroup && curGroup.repairCode">{{ `报修单${curGroup && curGroup.repairCode}`}}</span>
        <span v-else>{{`工单${curGroup && curGroup.orderId}`}}</span>的售后群，当前售后处理人：{{ remoteUser }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('im', ['remoteUser', 'groupId', 'memberList', 'groupList']),
    curGroup () {
      return this.groupList && this.groupList.find(item => item.groupId == this.groupId)
    }
  }
}
</script>
<style lang="scss" scoped>
.introduce {
  border-left: none;
  padding: 5px;
  .title1,
  .title2 {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
  }
  .people {
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33.3%;
      margin-top: 20px;
      img {
        width: 40px;
      }
    }
  }
  .introduce_con {
    margin-top: 30px;
    .txt {
      padding: 10px 20px;
      // text-indent:2em;
      line-height: 30px;
    }
  }
}
</style>
