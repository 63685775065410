<template>
  <div
    class="main_box"
    :style="{ background: pageName === 'group-chat' ? '#fff' : '' }"
  >
    <div class="main_box_con">
      <div v-loading="chatLoading">
        <el-row
          v-if="isHaveChatInfo"
          class="about_chat_box"
          :style="{ borderLeft: pageName === 'group-chat' ? 'none' : '' }"
        >
          <el-col :span="5" v-if="pageName !== 'group-chat'">
            <toggle />
          </el-col>
          <el-col :span="pageName !== 'group-chat' ? 14 : 19">
            <conversation ref="conversation" />
          </el-col>
          <el-col :span="5">
            <introduce />
          </el-col>
        </el-row>
        <div class="no_data" v-else>暂无相关聊天~</div>
      </div>
    </div>
    <el-dialog
      title="视频通话"
      :visible.sync="isShowNewInvitationDialog"
      width="400px"
      :modal="false"
      :close-on-click-modal="false"
    >
      <span>{{ this.getNewInvitationDialogContent() }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleRejectCall">拒绝</el-button>
        <el-button type="primary" @click="handleAccept">接听</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import toggle from './comp/toggle'
import conversation from './comp/conversation'
import introduce from './comp/introduce'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import { debounce } from '@/utils/common'
import { getUsernameByUserid } from './utils'
export default {
  components: {
    toggle,
    conversation,
    introduce
  },
  props: ['pageName', 'Pinfo'],
  data () {
    return {
      // isShowNewInvitationDialog: false
    }
  },
  computed: {
    ...mapGetters('im', ['isHaveChatInfo', 'userId']),
    ...mapState('im', ['chatLoading', 'userSig', 'isTimSDKReady']),
    ...mapState('trtc', ['meetingUserIdList', 'isShowNewInvitationDialog']),
    ...mapState(['loginUserInfo'])
  },
  mounted () {
    this.$bus.$on(
      'handleNewInvitationReceived',
      this.handleNewInvitationReceived
    )
    if (this.Pinfo) {
      this.enterChatAction(this.Pinfo.id)
        .then(_ => {})
        .catch(_ => {
          this.changeChatLoading(false)
        })
    }
  },
  methods: {
    ...mapMutations('trtc', [
      'updateCallStatus',
      'updateIsInviter',
      'userJoinMeeting',
      'dissolveMeeting',
      'changeShowVideoCallDialog',
      'changeInviterId',
      'changeShowNewInvitationDialog',
      'changeRoomID'
    ]),
    ...mapMutations('im', ['changeChatLoading']),
    ...mapActions('im', ['enterChatAction']),
    getNewInvitationDialogContent: function () {
      return `来自${this.inviterName}的视频通话`
    },
    handleNewInvitationReceived: async function (payload) {
      const { inviteID, sponsor, inviteData } = payload
      if (inviteData.callEnd) {
        // 最后一个人发送 invite 进行挂断
        this.updateCallStatus('idle')
        return
      }
      if (sponsor === this.loginUserInfo.id) {
        // 邀请人是自己, 同一个账号有可能在多端登录
        return
      }
      // 这里需要考虑忙线的情况
      if (this.callStatus === 'calling' || this.callStatus === 'connected') {
        await this.trtcCalling.reject({ inviteID, isBusy: true })
        return
      }
      this.inviteData = inviteData
      this.inviteID = inviteID
      this.isInviterCanceled = false
      this.updateIsInviter(false)
      this.updateCallStatus('calling')
      this.changeInviterId(sponsor)
      this.inviterName = getUsernameByUserid(sponsor)
      this.changeShowNewInvitationDialog(true)
    },
    handleRejectCall: async function () {
      try {
        const { callType } = this.inviteData
        await this.trtcCalling.reject({
          inviteID: this.inviteID,
          isBusy: false,
          callType
        })
        this.dissolveMeetingIfNeed()
      } catch (e) {
        this.dissolveMeetingIfNeed()
      }
    },
    handleAccept: debounce(async function () {
      try {
        const { callType, roomID } = this.inviteData
        this.userJoinMeeting(this.loginUserInfo.id)
        this.changeRoomID(roomID)
        await this.trtcCalling.accept({
          inviteID: this.inviteID,
          roomID,
          callType
        })
        this.changeShowNewInvitationDialog(false)
        this.changeShowVideoCallDialog(true)
      } catch (e) {
        this.dissolveMeetingIfNeed()
      }
    }, 500),
    dissolveMeetingIfNeed () {
      this.updateCallStatus('idle')
      this.changeShowNewInvitationDialog(false)
      if (this.meetingUserIdList.length < 2) {
        this.dissolveMeeting()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.about_chat_box {
  border: 1px solid #ddd;
}
.no_data {
  height: 500px;
  line-height: 500px;
  text-align: center;
  color: #aaa;
}
.chat-wrapper {
  margin-top: 8vh;
  width: 80vw;
  height: 80vh;
  max-width: 1280px;
  box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);

  .official-link {
    display: flex;
    text-decoration: none;
    color: #38c9ff;
    width: fit-content;
    float: right;
    height: 45px;
    align-items: center;
  }
}
</style>
