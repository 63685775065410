<template>
  <div class="video-call-section">
    <div :class="{ 'video-conference': true}">
      <div class="video-conference-list">
        <div
          v-for="userId in meetingUserIdList"
          :key="`video-${userId}`"
          :id="`video-${userId}`"
          :class="{'user-video-container': true, 'is-me': userId === loginUserInfo.id}"
        >
          <div class="user-status">
            <div
              :class="{'user-video-status': true, 'is-mute': isUserMute(muteVideoUserIdList, userId)}"
            ></div>
            <div
              :class="{'user-audio-status': true, 'is-mute': isUserMute(muteAudioUserIdList, userId)}"
            ></div>
          </div>
          <div class="video-item-username">{{userId2Name[userId] || userId}}</div>
        </div>
      </div>
      <div class="video-conference-action">
        <el-button
          class="action-btn"
          type="success"
          @click="toggleVideo"
        >{{isVideoOn ? '关闭摄像头' : '打开摄像头'}}</el-button>

        <el-button
          class="action-btn"
          type="success"
          @click="toggleAudio"
        >{{isAudioOn ? '关闭麦克风' : '打开麦克风'}}</el-button>

        <el-button class="action-btn" type="danger" @click="handleHangup">挂断</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getUsernameByUserid } from '../../utils'
export default {
  name: 'VideoCall',
  components: {
  },
  computed: {
    ...mapGetters('im', ['memberList']),
    ...mapState(['loginUserInfo']),
    ...mapState('trtc', ['inviterId', 'isShowVideoCall', 'callStatus', 'isInviter', 'meetingUserIdList', 'muteVideoUserIdList', 'muteAudioUserIdList'])
  },
  data () {
    return {
      // isShowVideoCall: false,
      isVideoOn: true,
      isAudioOn: true,
      userId2Name: {}
    }
  },
  mounted () {
    if (this.callStatus === 'connected' && !this.isInviter) {
      this.startMeeting()
      this.updateUserId2Name(this.meetingUserIdList)
    }
  },
  destroyed () {
    this.updateMuteVideoUserIdList([])
    this.updateMuteVideoUserIdList([])
    if (this.callStatus === 'connected') {
      this.trtcCalling.hangup()
      this.updateCallStatus('idle')
    }
  },
  watch: {
    callStatus: function (newStatus, oldStatus) {
      // 作为被邀请者, 建立通话连接
      if (newStatus !== oldStatus && newStatus === 'connected') {
        this.startMeeting()
        this.updateUserId2Name(this.meetingUserIdList)
      }
    },
    meetingUserIdList: function (newList, oldList) {
      if (newList !== oldList || newList.length !== oldList) {
        this.updateUserId2Name(newList)
      }
    }
  },
  methods: {
    ...mapMutations('trtc', ['changeShowVideoCall', 'changeShowVideoCallDialog', 'updateMuteVideoUserIdList', 'updateMuteAudioUserIdList', 'updateCallStatus', 'dissolveMeeting', 'updateCallStatus']),
    handleCancelCallUser: function () {
      this.trtcCalling.hangup()
      this.dissolveMeeting()
      this.updateCallStatus('idle')
    },
    startMeeting: function () {
      if (this.meetingUserIdList.length >= 3) {
        // 多人通话
        const lastJoinUser = this.meetingUserIdList[
          this.meetingUserIdList.length - 1
        ]
        this.trtcCalling.startRemoteView({
          userID: lastJoinUser,
          videoViewDomID: `video-${lastJoinUser}`
        })
        return
      }
      this.changeShowVideoCall(true)
      this.trtcCalling.startLocalView({
        userID: this.loginUserInfo.id,
        videoViewDomID: `video-${this.loginUserInfo.id}`
      })
      const otherParticipants = this.meetingUserIdList.filter(
        userId => userId !== this.loginUserInfo.id
      )
      otherParticipants.forEach(userId => {
        this.trtcCalling.startRemoteView({
          userID: userId,
          videoViewDomID: `video-${userId}`
        })
      })
    },
    handleHangup: function (userId) {
      this.trtcCalling.hangup()
      this.changeShowVideoCall(false)
      if (this.loginUserInfo.id !== this.inviterId) {
        // 当前登录人不是邀请人 就关闭弹窗
        this.changeShowVideoCallDialog(false)
      }
      this.updateCallStatus('idle')
    },
    toggleVideo: function () {
      this.isVideoOn = !this.isVideoOn
      if (this.isVideoOn) {
        this.trtcCalling.openCamera()
        const muteUserList = this.muteVideoUserIdList.filter(
          userId => userId !== this.loginUserInfo.id
        )
        this.updateMuteVideoUserIdList(muteUserList)
      } else {
        this.trtcCalling.closeCamera()
        const muteUserList = this.muteVideoUserIdList.concat(
          this.loginUserInfo.id
        )
        this.updateMuteVideoUserIdList(muteUserList)
      }
    },
    toggleAudio: function () {
      this.isAudioOn = !this.isAudioOn
      this.trtcCalling.setMicMute(!this.isAudioOn)
      if (this.isAudioOn) {
        const muteUserList = this.muteAudioUserIdList.filter(
          userId => userId !== this.loginUserInfo.id
        )
        this.updateMuteAudioUserIdList(muteUserList)
      } else {
        const muteUserList = this.muteAudioUserIdList.concat(
          this.loginUserInfo.id
        )
        this.updateMuteAudioUserIdList(muteUserList)
      }
    },
    isUserMute: function (muteUserList, userId) {
      return muteUserList.indexOf(userId) !== -1
    },
    updateUserId2Name: async function (userIdList) {
      const userId2Name = {}
      const loginUserId = this.loginUserInfo.id
      for (let i = 0; i < userIdList.length; i++) {
        const userId = userIdList[i]
        if (!this.userId2Name[userId]) {
          const userName = getUsernameByUserid(userId)
          userId2Name[userId] = userName
          if (loginUserId === userId) {
            userId2Name[userId] += '(me)'
          }
        }
      }
      this.userId2Name = {
        ...this.userId2Name,
        ...userId2Name
      }
    }
  }
}
</script>

<style scoped>
.video-call-section-header {
  font-size: 24px;
}
.video-call-section-title {
  margin-top: 30px;
  font-size: 20px;
}
.video-conference {
  /* display: none; */
  margin-top: 20px;
}
/* .video-conference.is-show {
  display: block;
} */

.video-conference-list {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.user-video-container {
  position: relative;
  text-align: left;
  width: 460px;
  height: 340px;
  margin: 10px;
}

.user-video-status {
  position: absolute;
  right: 50px;
  bottom: 20px;
  width: 24px;
  height: 27px;
  z-index: 10;
  background-image: url("~@/assets/images/camera-on.png");
  background-size: cover;
}
.user-video-status.is-mute {
  background-image: url("~@/assets/images/camera-off.png");
}

.user-audio-status {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 22px;
  height: 27px;
  z-index: 10;
  background-image: url("~@/assets/images/mic-on.png");
  background-size: cover;
}

.user-audio-status.is-mute {
  background-image: url("~@/assets/images/mic-off.png");
}

.video-conference-action {
  margin-top: 10px;
}

.video-item-username {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .video-call-section {
    width: 100%;
  }
  .video-conference-list {
    margin: 0;
    padding: 10px;
  }
  .user-video-container {
    margin: 5px;
  }
}
</style>
