<template>
  <div class="toggle">
    <div class="toggle_title">客户相关群聊</div>
    <ul class="toggle_con">
      <li
        v-for="(item, index) in enterChatInfo.userGroupList"
        :key="index"
        :class="item.groupId == currentGroupId ? 'active' : ''"
        @click="groupToggle(item)"
      >
        <div>
          <span class="circle_icon" v-if="handleIsNewMsg(item.groupId)"></span>
          群号:{{ item.groupId }} 设备台数:{{ item.itemCount }}</div>
        <div v-if="item.repairCode">报修单号：{{ item.repairCode }}</div>
        <div v-else>工单:{{ item.orderId }}</div>
        <div>报修时间:{{ item.createdTime }}</div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  computed: {
    ...mapState('im', ['enterChatInfo', 'currentGroupId', 'newMsgGroup']),
    ...mapGetters('im', ['groupId'])
  },

  // watch: {
  //   currentMessageList: {
  //     handler () {
  //       this.$bus.$emit('scrollMessageListToButtom')
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    ...mapMutations('im', ['resetIMState', 'removeNewMsgGroup', 'changeChatLoading']),
    ...mapActions('im', ['enterChatAction']),
    groupToggle (item) {
      this.enterChatAction(item.orderId).then(_ => {
      }).catch(_ => {
        this.changeChatLoading(false)
      })
      this.removeNewMsgGroup(item.groupId)
    },
    handleIsNewMsg (groupId) {
      if (this.groupId == groupId) {
        return false
      }
      return this.newMsgGroup.includes(groupId + '')
    }
  }
}
</script>
<style lang="scss" scoped>
.toggle {
  padding-left: 10px;
  .toggle_title {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
  }
  .toggle_con {
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    li {
      margin-top: 20px;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
      &.active {
        background-color: #eee;
      }
      .circle_icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}
</style>
