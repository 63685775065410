<template>
  <div class="calling-member-list">
    <div class="header">群成员（{{ memberList && memberList.length }}）</div>
    <el-checkbox-group
      class="people"
      v-model="callingList"
      @change="handleCheckedMembersChange"
    >
      <el-checkbox
        class="item"
        v-for="(item, index) in memberList"
        :disabled="loginUserInfo.id === item.id"
        :label="item.id"
        :key="index"
      >
        <img width="40" src="~@/assets/images/avatar.png" />
        <div>{{ item.imMemberName }}</div>
      </el-checkbox>
    </el-checkbox-group>
    <div class="btns">
      <span class="calling-btn" @click="btnClick(false)">取消</span>
      <span class="calling-btn" @click="btnClick(true)">确定</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['loginUserInfo']),
    ...mapGetters('im', ['remoteUser', 'groupId', 'memberList', 'groupList'])
  },
  data () {
    return {
      callingList: []
    }
  },
  methods: {
    handleCheckedMembersChange () {},
    btnClick (val) {
      this.$emit('btnClick', val, this.callingList)
      this.callingList = []
    }
  }
}
</script>
<style lang="scss" scoped>
.calling-member-list {
  padding: 10px;
  .header {
    height: 40px;
    line-height: 40px;
  }
  .people {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      img {
        margin-right: 10px;
      }
    }
  }
  .btns {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 10px;
    .calling-btn {
      cursor: pointer;
      padding: 6px 12px;
      background: #00a4ff;
      color: #ffffff;
      font-size: 14px;
      border-radius: 20px;
      margin-left: 13px;
    }
  }
}
</style>
