<template>
  <div class="conversation">
    <div class="header">
      {{ groupId }}
    </div>
    <div class="content message-list" ref="message-list">
      <div class="more" v-if="!isCompleted">
        <el-button type="text" @click="getMoreMessage">查看更多</el-button>
      </div>
      <div class="no-more" v-else>没有更多了</div>
      <messageItem
        v-for="(message,index) in currentMessageList"
        :key="index"
        :message="message"
      />
    </div>
    <div class="footer">
      <div class="icons">
        <i
          class="el-icon-picture-outline"
          title="发图片"
          @click="handleSendImageClick"
        ></i>
        <i
          class="el-icon-video-camera"
          title="发视频"
          @click="handleSendVideoClick"
        ></i>
        <!-- <i
          class="el-icon-folder-opened"
          title="发文件"
          @click="handleSendFileClick"
        ></i> -->
        <i
          class="el-icon-phone-outline"
          title="视频通话"
          @click="trtcCallingClick"
        ></i>
      </div>
      <div class="input_box">
        <textarea
          ref="text-input"
          rows="4"
          resize="false"
          class="text-input"
          v-model="messageContent"
          @focus="focus = true"
          @blur="focus = false"
          @keydown.enter.exact.prevent="handleEnter"
          @keyup.ctrl.enter.prevent.exact="handleLine"
        >
        </textarea>
        <el-tooltip
          class="item"
          effect="dark"
          content="按Enter发送消息，Ctrl+Enter换行"
          placement="left-start"
        >
          <div class="btn-send">
            <div class="el-icon-position" @click="handleEnter"></div>
          </div>
        </el-tooltip>
      </div>
      <input
        type="file"
        id="imagePicker"
        ref="imagePicker"
        accept=".jpg, .jpeg, .png, .gif, .bmp"
        @change="sendImage"
        style="display:none"
      />
      <input
        type="file"
        id="filePicker"
        ref="filePicker"
        @change="sendFile"
        style="display:none"
      />
      <input
        type="file"
        id="videoPicker"
        ref="videoPicker"
        @change="sendVideo"
        style="display:none"
        accept=".mp4"
      />
    </div>
    <callingMemberList
      class="calling-list"
      v-show="callingMemberShow"
      @btnClick="callingMemberBtn"
    />
    <dialogComp
      width="1000px"
      title="视频通话区域"
      :isShow="isShowVideoCallDialog"
      :isShowFooter="false"
      :modal="false"
      @close="closeVideoCallDialog"
    >
      <h2 v-show="!isShowVideoCall" style="height:200px">呼叫中...</h2>
      <videoCall v-show="isShowVideoCall" />
    </dialogComp>
  </div>
</template>
<script>
import messageItem from './window-comp/message-item'
import videoCall from './window-comp/video-call'
import callingMemberList from './window-comp/calling-member-list'
import { sendGroupMsg, delHistoryMsg } from '@/api'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
export default {
  components: {
    messageItem,
    callingMemberList,
    videoCall
  },
  computed: {
    ...mapState(['loginUserInfo', 'drawerShow']),
    ...mapState('im', ['currentMessageList', 'isCompleted', 'currentMessageList']),
    ...mapState('trtc', [
      'inviterId',
      'isShowVideoCallDialog',
      'isShowVideoCall',
      'callStatus',
      'isInviter'
    ]),
    ...mapGetters('im', ['groupId', 'userId']),
    conversationType () {
      return this.TIM.TYPES.CONV_GROUP
    }
  },
  data () {
    return {
      messageContent: '',
      callingMemberShow: false
    }
  },
  mounted () {
    this.$bus.$on('scrollMessageListToButtom', this.scrollMessageListToButtom)
    this.scrollMessageListToButtom()
  },
  watch: {
    drawerShow: {
      // immediate: true,
      handler (val) {
        if (val) {
          this.scrollMessageListToButtom()
        } else {
          this.resetIMState()
        }
      }
    }
  },
  methods: {
    ...mapMutations('im', [
      'changeCallingList',
      'pushCurrentMessageList',
      'resetIMState'
    ]),
    ...mapActions('im', ['getMessageList']),
    ...mapMutations('trtc', [
      'changeShowVideoCallDialog',
      'userJoinMeeting',
      'updateCallStatus',
      'updateIsInviter',
      'changeShowVideoCall'
    ]),
    // 发送消息成功也给后台发个请求 请求成功后再调im接口
    sendMyRequest (type, content, fileName) {
      // 发送消息成功也给后台发个请求
      const params = {
        //  数据类型0:文字，1：图片，2：视频，3：文件
        type,
        groupId: this.groupId,
        fromUserId: this.loginUserInfo.id,
        content,
        fileName
      }
      return sendGroupMsg(params)
    },
    // im发送失败需要调用后台删除消息接口
    delMsg (id) {
      delHistoryMsg({
        id
      })
    },
    // 发送文本信息
    async sendTextMessage () {
      if (
        this.messageContent === '' ||
        this.messageContent.trim().length === 0
      ) {
        this.messageContent = ''
        this.$message.info('不能发送空消息哦！')
        return
      }
      const message = this.tim.createTextMessage({
        to: this.groupId,
        conversationType: this.conversationType,
        payload: { text: this.messageContent }
      })
      this.sendMyRequest(0, this.messageContent, '').then(res => {
        this.pushCurrentMessageList(message)
        this.scrollMessageListToButtom()
        const msgId = res.data
        this.tim.sendMessage(message).catch(error => {
          this.delMsg({ id: msgId })
          this.$message.error(error.message)
        })
      })
      this.messageContent = ''
    },
    // 发送图片
    sendImage () {
      const imgEl = document.getElementById('imagePicker')
      const message = this.tim.createImageMessage({
        to: this.groupId,
        conversationType: this.conversationType,
        payload: {
          file: imgEl // 或者用event.target
        },
        onProgress: percent => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        }
      })
      this.pushCurrentMessageList(message)
      this.scrollMessageListToButtom()
      this.tim
        .sendMessage(message)
        .then(async () => {
          await this.sendMyRequest(
            1,
            message.payload.imageInfoArray[0].imageUrl,
            imgEl.files[0].name
          )
          this.$refs.imagePicker.value = null
        })
        .catch(imError => {
          this.$message.error(imError.message)
        })
    },
    // 发送视频
    sendVideo () {
      const videoEl = document.getElementById('videoPicker')
      const message = this.tim.createVideoMessage({
        to: this.groupId,
        conversationType: this.conversationType,
        payload: {
          file: videoEl // 或者用event.target
        },
        onProgress: percent => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        }
      })
      this.pushCurrentMessageList(message)
      this.scrollMessageListToButtom()
      this.tim
        .sendMessage(message)
        .then(async () => {
          await this.sendMyRequest(
            2,
            message.payload.remoteVideoUrl,
            videoEl.files[0].name
          )
          this.$refs.videoPicker.value = null
        })
        .catch(imError => {
          this.$message.error(imError.message)
        })
    },
    // 发送文件
    sendFile () {
      const message = this.tim.createFileMessage({
        to: this.groupId,
        conversationType: this.conversationType,
        payload: {
          file: document.getElementById('filePicker') // 或者用event.target
        },
        onProgress: percent => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        }
      })
      this.pushCurrentMessageList(message)
      this.scrollMessageListToButtom()
      this.tim
        .sendMessage(message)
        .then(async () => {
          const { fileName, fileUrl } = message.payload
          await this.sendMyRequest(3, fileUrl, fileName)
          this.$refs.filePicker.value = null
        })
        .catch(imError => {
          this.$message.error(imError.message)
        })
    },
    // 视频通话
    trtcCallingClick () {
      this.callingMemberShow = true
    },
    // 点击视频通话 出现弹窗点击确定取消按钮
    callingMemberBtn (bool, callingList) {
      this.callingMemberShow = bool
      if (bool && callingList.length === 0) {
        this.$message.error('请选择人员')
      } else {
        this.callingMemberShow = false
      }
      if (bool) {
        const callingData = {
          userIDList: callingList,
          type: 2,
          groupID: this.groupId,
          timeout: 20
        }
        this.changeShowVideoCallDialog(true)
        this.handleCallUser(callingData)
      }
    },
    handleCallUser (callingData) {
      this.trtcCalling.groupCall({ ...callingData })
      this.userJoinMeeting(this.loginUserInfo.id)
      this.updateCallStatus('calling')
      this.updateIsInviter(true)
    },
    handleSendImageClick () {
      this.$refs.imagePicker.click()
    },
    handleSendFileClick () {
      this.$refs.filePicker.click()
    },
    handleSendVideoClick () {
      this.$refs.videoPicker.click()
    },
    handleEnter () {
      this.sendTextMessage()
    },
    handleLine () {
      this.messageContent += '\n'
    },
    // 获取更多消息
    getMoreMessage () {
      this.getMessageList(`GROUP${this.groupId}`)
    },
    // 直接滚到底部
    scrollMessageListToButtom () {
      this.$nextTick(() => {
        const messageListNode = this.$refs['message-list']
        if (!messageListNode) {
          return
        }
        setTimeout(() => {
          messageListNode.scrollTop = messageListNode.scrollHeight
        }, 500)
      })
    },
    // ======视频操作========
    closeVideoCallDialog () {
      this.trtcCalling.hangup()
      this.changeShowVideoCall(false)
      this.changeShowVideoCallDialog(false)
      this.updateCallStatus('idle')
    }
  }
}
</script>
<style lang="scss" scoped>
.conversation {
  position: relative;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 540px;
  .calling-list {
    position: absolute;
    top: 40px;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f3f3f3;
  }
  .header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
  }
  .content {
    position: relative;
    flex:1;
    overflow-y: scroll;
    border-top: 1px solid #ddd;
    padding: 0 20px;
    .more,
    .no-more {
      text-align: center;
    }
  }
  .footer {
    height: 100px;
    border-top: 1px solid #ddd;
    padding: 0 10px;
    overflow: hidden;
    position: relative;
    .icons {
      padding: 5px 0;
      i {
        font-size: 24px;
        color: #808080;
        margin-right: 10px;
        cursor: pointer;
      }
    }
    .text-input {
      font-size: 16px;
      width: 100%;
      box-sizing: box-sizing;
      border: none;
      outline: none;
      resize: none;
      background-color: transparent;
      color: rgba(0, 0, 0, 0.7);
    }
    .btn-send {
      position: absolute;
      right: 10px;
      bottom: 10px;
      cursor: pointer;
      .el-icon-position {
        font-size: 24px;
        color: #385bff;
      }
    }
  }
}
</style>
