<template>
  <div class="message-wrapper">
    <div class="group-layout" :class="messagePosition">
      <div class="col-1">
        <img width="40" src="~@/assets/images/avatar.png" />
        <span>{{ handleName(message.from) }}</span>
      </div>
      <div class="col-2">
        <!-- 消息主体 -->
        <!-- <message-header v-if="showMessageHeader" :message="message" /> -->
        <div class="content-wrapper">
          <div class="text" v-if="message.type === TIM.TYPES.MSG_TEXT">
            <span>{{ message.payload.text }}</span>
          </div>
          <div class="image" v-if="message.type === TIM.TYPES.MSG_IMAGE">
            <el-image
              style="width: 100px; height: 100px"
              :src="message.payload.imageInfoArray[0].imageUrl"
              :preview-src-list="[message.payload.imageInfoArray[0].imageUrl]"
            >
            </el-image>
          </div>
          <div class="video" v-if="message.type === TIM.TYPES.MSG_VIDEO">
            <video controls>
              <source :src="message.payload.remoteVideoUrl" type="video/mp4" />
            </video>
          </div>
          <div
            class="file"
            title="单击下载"
            @click="downloadFile(message.payload)"
             v-if="message.type === TIM.TYPES.MSG_FILE"
          >
            <div class="header">
              <i class="el-icon-document file-icon"></i>
              <div class="file-element">
                <span class="file-name">{{ message.payload.fileName }}</span>
                <span class="file-size">{{ (message.payload.fileSize / 1024).toFixed(2) + 'kb' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getUsernameByUserid } from '../../utils'
export default {
  components: {},
  props: ['message'],
  data () {
    return {
      zoomPicShow: false,
      zoomPicUrl: ''
    }
  },
  computed: {
    ...mapGetters('im', ['memberList']),
    isMine () {
      return this.message.flow === 'out'
    },
    messagePosition () {
      if (
        ['TIMGroupTipElem', 'TIMGroupSystemNoticeElem'].includes(
          this.message.type
        )
      ) {
        return 'position-center'
      }
      if (this.isMine) {
        return 'position-right'
      } else {
        return 'position-left'
      }
    }
  },
  methods: {
    handleName (id) {
      return getUsernameByUserid(id)
    },
    downloadFile ({ fileUrl, fileName }) {
      // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
      if (window.fetch) {
        fetch(fileUrl)
          .then(res => res.blob())
          .then(blob => {
            const a = document.createElement('a')
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName
            a.click()
          })
      } else {
        const a = document.createElement('a')
        a.href = fileUrl
        a.target = '_blank'
        a.download = fileName
        a.click()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.message-wrapper {
  margin: 20px 0;
  .group-layout {
    display: flex;
    // align-items: center;
    .col-1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .col-2 {
      display: flex;
      flex-direction: column;
    }
    .col-3 {
      width: 30px;
    }

    &.position-left {
      .col-2 {
        align-items: flex-start;
        .content-wrapper {
          div {
            margin-left: 10px;
          }
        }
      }
    }

    &.position-right {
      flex-direction: row-reverse;
      .col-2 {
        align-items: flex-end;
        .text,.header {
          background-color: #5cadff;
          color: #fff;
        }
        .content-wrapper {
          div {
            margin-right: 10px;
          }
        }
      }
    }

    &.position-center {
      justify-content: center;
    }
    .content-wrapper {
      .text,.header {
        background-color: #ddd;
        padding: 8px;
        border-radius: 5px;
      }
      .image {
        width: 100px;
        img {
          width: 100%;
        }
      }
      .video {
        width: 200px;
        video {
          width: 100%;
        }
      }
      .file {
        cursor: pointer;
        .header {
          display: flex;
          align-items: center;
          i {
            font-size: 30px;
          }
          .file-element {
            display: flex;
            flex-direction: column;
          }
        }

      }
    }
  }
}
</style>
